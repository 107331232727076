export class VulnerabilityPolicy {
    constructor(allow_unknown_severity = false, created_at = new Date(), description = '', min_severity = 'Critical', name = '', on_violation_quarantine = true, slug_perm, updated_at = new Date(), package_query_string) {
        this.allow_unknown_severity = false;
        this.created_at = new Date();
        this.description = '';
        this.min_severity = 'Critical';
        this.name = '';
        this.on_violation_quarantine = true;
        this.slug_perm = '';
        this.updated_at = new Date();
        this.package_query_string = null;
        if (slug_perm) {
            this.slug_perm = slug_perm;
        }
        this.allow_unknown_severity = allow_unknown_severity;
        this.description = description;
        this.name = name;
        this.on_violation_quarantine = on_violation_quarantine;
        this.min_severity = min_severity;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.package_query_string = package_query_string;
    }
}

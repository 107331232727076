import { handleErrors } from '../utils/error';
const commonHeaders = (csrfToken) => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Csrftoken': csrfToken,
    'X-Browser-Jwt-Auth': 'true',
});
const commonFetch = (endpoint, csrfToken, body = null, method = 'GET') => fetch(endpoint, {
    method,
    headers: commonHeaders(csrfToken),
    credentials: 'include',
    body,
})
    .then(handleErrors)
    .then((res) => {
    if (res.headers.get('Content-Type') !== 'application/json') {
        return true;
    }
    return res.json();
});
const convertSettingForAPI = (setting) => ({
    name: setting.name,
    enabled: setting.enabled,
    provider_url: setting.provider_url,
    claims: setting.claims,
    service_accounts: setting.service_accounts,
});
export function getProviderSettingsList(url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/openid-connect/`;
    return commonFetch(endpoint, csrfToken);
}
export function createProviderSetting(url, csrfToken, org, setting) {
    const endpoint = `${url}v1/orgs/${org}/openid-connect/`;
    const convertedSetting = convertSettingForAPI(setting);
    return commonFetch(endpoint, csrfToken, JSON.stringify(convertedSetting), 'POST');
}
export function deleteProviderSetting(slug_perm, url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/openid-connect/${slug_perm}`;
    return commonFetch(endpoint, csrfToken, null, 'DELETE');
}
export function editProviderSetting(url, csrfToken, org, setting) {
    const endpoint = `${url}v1/orgs/${org}/openid-connect/${setting.slug_perm}/`;
    const convertedSetting = convertSettingForAPI(setting);
    return commonFetch(endpoint, csrfToken, JSON.stringify(convertedSetting), 'PUT');
}
